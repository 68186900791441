















































import { Component } from 'vue-property-decorator';
import { QuestionView } from '@/base-course/Viewable';
import { FallingLettersQuestion } from './index';

interface Letter {
  id: number;
  char: string;
  x: number;
  y: number;
}

interface TreePosition {
  id: number;
  left: number;
  height: number;
  scale: number;
}

@Component({})
export default class FallingLettersView extends QuestionView<FallingLettersQuestion> {
  letters: Letter[] = [];
  gameOver = false;
  gameOverMessage = '';
  timeLeft = 30;
  score = 0;
  currentSpeed = 2;
  letterId = 0;
  gameLoop: number | null = null;
  lastSpawn = 0;
  lastUpdate = 0;
  spawnInterval = 1000; // Spawn a new letter every second

  get question() {
    return new FallingLettersQuestion(this.data);
  }

  treePositions: TreePosition[] = [];

  mounted() {
    window.addEventListener('keypress', this.handleKeyPress);
    this.startGame();
    this.generateTrees();
  }

  generateTrees() {
    this.treePositions = Array.from({ length: 7 }, (_, i) => ({
      id: i,
      left: 20 + i * Math.random() * 30 - 10,
      height: 100 + Math.random() * 120,
      scale: 1 + Math.random() * 2,
    }));
  }

  destroyed() {
    window.removeEventListener('keypress', this.handleKeyPress);
    if (this.gameLoop) cancelAnimationFrame(this.gameLoop);
  }

  startGame() {
    this.timeLeft = this.question.gameLength;
    this.currentSpeed = this.question.initialSpeed;
    this.spawnInterval = this.question.spawnInterval * 1000;
    this.lastUpdate = performance.now();
    this.lastSpawn = performance.now();
    this.gameLoop = requestAnimationFrame(this.update);
  }

  spawnLetter() {
    if (!this.$refs.gameArea) return;

    const gameArea = this.$refs.gameArea as HTMLElement;
    const letter: Letter = {
      id: this.letterId++,
      char: String.fromCharCode(65 + Math.floor(Math.random() * 26)),
      x: Math.random() * (gameArea.clientWidth - 30),
      y: -30, // Start slightly above the visible area
    };

    // [ ] next: words instead of letters
    //
    // if (Math.random() > 0.8) {
    //   letter.char = 'word';
    // }

    this.letters.push(letter);
  }

  update(timestamp: number) {
    if (this.gameOver) return;

    const deltaTime = (timestamp - this.lastUpdate) / 1000;
    this.lastUpdate = timestamp;

    // Update time
    this.timeLeft -= deltaTime;
    this.currentSpeed += this.question.acceleration * deltaTime;

    // Check win condition
    if (this.timeLeft <= 0) {
      this.win();
      return;
    }

    // Spawn new letters
    if (timestamp - this.lastSpawn >= this.spawnInterval) {
      this.spawnLetter();
      this.lastSpawn = timestamp;
      // Gradually decrease spawn interval (make it harder)
      this.spawnInterval = Math.max(500, this.spawnInterval - 10);
    }

    // Update letters positions
    const gameArea = this.$refs.gameArea as HTMLElement;
    this.letters = this.letters.filter(letter => {
      letter.y += this.currentSpeed;
      if (letter.y > gameArea.clientHeight) {
        this.lose();
        return false;
      }
      return true;
    });

    this.gameLoop = requestAnimationFrame(this.update);
  }

  handleKeyPress(event: KeyboardEvent) {
    if (this.gameOver) return;

    const pressedKey = event.key.toUpperCase();
    const letterIndex = this.letters.findIndex(l => l.char === pressedKey);

    if (letterIndex !== -1) {
      this.letters.splice(letterIndex, 1);
      this.score++;
    }
  }

  win() {
    this.gameOver = true;
    this.gameOverMessage = 'You Win!';
    this.submitAnswer('win');
  }

  lose() {
    this.gameOver = true;
    this.gameOverMessage = 'Game Over!';
    this.submitAnswer('lose');
  }
}
