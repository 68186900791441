







import { Component } from 'vue-property-decorator';
import { QuestionView } from '@/base-course/Viewable';
import { TypeLetterQuestion } from './index';

@Component({})
export default class LetterQuestionView extends QuestionView<TypeLetterQuestion> {
  get question() {
    return new TypeLetterQuestion(this.data);
  }

  mounted() {
    window.addEventListener('keypress', this.handleKeyPress);
  }

  destroyed() {
    window.removeEventListener('keypress', this.handleKeyPress);
  }

  handleKeyPress(event: KeyboardEvent) {
    const pressedKey = event.key.toLowerCase();
    const targetKey = this.question.letter.toLowerCase();

    if (pressedKey === targetKey) {
      const letterDisplay = document.querySelector('.letter-display');
      letterDisplay?.classList.add('pressed');

      // Remove pressed class and submit answer after key release
      const handleKeyUp = (e: KeyboardEvent) => {
        if (e.key.toLowerCase() === targetKey) {
          letterDisplay?.classList.remove('pressed');
          this.submitAnswer(pressedKey);
          window.removeEventListener('keyup', handleKeyUp);
        }
      };

      window.addEventListener('keyup', handleKeyUp);
    } else {
      this.submitAnswer(pressedKey);
    }
  }
}
