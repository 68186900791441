var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game-container"},[_c('div',{staticClass:"stats"},[_c('div',{staticClass:"time"},[_vm._v("Time: "+_vm._s(_vm.timeLeft.toFixed(1))+"s")]),_c('div',{staticClass:"score"},[_vm._v("Score: "+_vm._s(_vm.score))])]),_c('div',{ref:"gameArea",staticClass:"game-area"},[_vm._l((_vm.letters),function(letter){return _c('div',{key:letter.id,staticClass:"falling-letter",style:({
        left: letter.x + 'px',
        top: letter.y + 'px',
      })},[_c('div',{staticClass:"meteor-effect"}),_c('span',{staticClass:"letter-text"},[_vm._v(_vm._s(letter.char))])])}),_vm._m(0),_c('div',{staticClass:"trees"},_vm._l((_vm.treePositions),function(tree){return _c('div',{key:tree.id,staticClass:"tree",style:({
          left: ((tree.left) + "%"),
          height: ((tree.height) + "px"),
          transform: ("scale(" + (tree.scale) + ")"),
        })},[_c('div',{staticClass:"tree-crown"}),_c('div',{staticClass:"tree-trunk"})])}),0)],2),(_vm.gameOver)?_c('div',{staticClass:"game-over"},[_vm._v(" "+_vm._s(_vm.gameOverMessage)+" ")]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grass-container"},[_c('div',{staticClass:"grass"}),_c('div',{staticClass:"grass-overlay"})])}]

export { render, staticRenderFns }